<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>{{ $t("select_your_income") }}</h1>
          <p class="fw-medium text-gray">
            {{ $t("expat_step9_detail") }}
          </p>
          <div class="d-flex">
            <b-spinner
              class="mx-auto"
              large
              label="Spinning"
              variant="primary"
              v-if="getLoadings.nordigen_transactions"
            >
            </b-spinner>
          </div>
          <div class="transactions mb-5 mt-3">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                class="mb-2"
                v-model="form"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="{
                  personalMonthlyIncome: transaction[0].amount,
                  currency: transaction[0].currency,
                  income_employer: transaction[0].merchant_name,
                }"
                v-for="(transaction, key) in transactions"
                :key="key"
              >
                <div v-for="tr in transaction" :key="tr.id">
                  {{ `${tr.amount} ${tr.currency} - ${tr.merchant_name} - ` }}
                  {{ tr.date | moment("DD-MM-YYYY") }}
                </div>
              </b-form-radio>
            </b-form-group>
          </div>
          <div
            class="text-muted text-center my-5"
            v-if="transactions.length == 0 && !getLoadings.nordigen_transactions"
          >
            {{ $t("No transactions found") }}
          </div>
          <small class="text-danger" v-if="getErrors.nordigen_transactions">
            {{ getErrors.nordigen_transactions }}
          </small>
          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              :disabled="
                !(form && form.personalMonthlyIncome) ||
                (getLoadings.nordigen_set_transaction && !skip)
              "
              @click="onSubmit"
            >
              {{ $t("Next step") }}
              <b-spinner
                v-if="getLoadings.nordigen_set_transaction && !skip"
                small
                label="Spinning"
                variant="white"
              >
              </b-spinner>
            </button>
            <button
              class="btn btn-outline-primary btn-block mt-3"
              @click="skipTransaction()"
              :disabled="getLoadings.nordigen_set_transaction"
            >
              <b-spinner
                v-if="getLoadings.nordigen_set_transaction && skip"
                small
                label="Spinning"
                variant="primary"
              >
              </b-spinner>
              {{ $t("Can't see correct income, skip this step") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <!-- <img src="../../../assets/images/expat/step8.png" alt=""> -->
          <RightPoints active="5" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";

export default {
  data() {
    return {
      error: "",
      form: null,
      skip: false,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["transactions"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  created() {
    this.step = +this.$route.params.step;
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      this.getTenant(tenant_id);
    }
    this.getTransactions(tenant_id);
  },
  methods: {
    ...mapActions("nordigen", ["getTransactions", "setTransaction"]),
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async onSubmit() {
      // let personalMonthlyIncome = this.form.personalMonthlyIncome;
      // personalMonthlyIncome = personalMonthlyIncome.replace(".", "");
      // personalMonthlyIncome = personalMonthlyIncome.replace(",", ".");
      await this.setTransaction({ tenant_id: this.tenant.id, payload: this.form });
      this.$router.push({
        name: "ExpatStep10",
        params: { tenant_id: this.tenant.id },
      });
    },
    async skipTransaction() {
      this.skip = true;
      await this.setTransaction({
        tenant_id: this.tenant.id,
        payload: {
          income_employer: null,
        },
      });
      this.$router.push({
        name: "ExpatStep10",
        params: { tenant_id: this.tenant.id },
      });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 450px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.transactions {
  max-height: 350px;
  overflow-y: scroll;
}
</style>
